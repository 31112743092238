<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
         <card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ $t('elearning_tim.evaluation') + ' ' + $t('globalTrans.details') }}
                        </list-report-head>
                    </b-col>
              </b-row>
              <b-row>
              <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                      <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tim.exam_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ examTypeName }}</td>
                      <th style="width: 15%"  class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                      <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                      <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_tim.exam_date') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ formData.exam_date | dateFormat }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.course') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.course_name_bn : formData.course_name }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.exam_time_start') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ formData.exam_time_start }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ formData.exam_time_end }}</td>
                      </tr>
                  </tbody>
                  </b-table-simple>

                  <div class="question-area" v-for="(evaluationQuestion, questionIndex) in formData.evaluation_questions" :key="questionIndex">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                      <tbody>
                      <tr>
                      <th colspan="6">{{$t('elearning_tim.question')}} {{ $n(questionIndex + 1) }}</th>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tim.question_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ getQuestionType(evaluationQuestion.question_type) }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tim.question_en') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ evaluationQuestion.question }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_tim.question_bn') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ evaluationQuestion.question_bn }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tim.description_en') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ evaluationQuestion.description }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_tim.description_bn') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ evaluationQuestion.description_bn }}</td>
                      </tr>
                      <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.marks') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%" class="text-left">{{ evaluationQuestion.marks }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.attachment') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%"><a v-if="evaluationQuestion.attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationQuestion.attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a></td>
                      </tr>
                      </tbody>
                  </b-table-simple>
                  <table class="table" v-if="evaluationQuestion.evaluation_options.length">
                      <thead class="thead">
                      <tr>
                      <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                      <th class="text-center">{{ $t('elearning_tim.is_correct') }}</th>
                      <th>{{ $t('elearning_tim.option_en') }}</th>
                      <th>{{ $t('elearning_tim.option_bn') }}</th>
                      <th>{{ $t('globalTrans.attachment') }}</th>
                      </tr>
                      </thead>
                      <tr v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="(questionIndex+1)+(optionIndex+1)">
                      <td class="text-center">{{ $n(optionIndex + 1) }}</td>
                      <td class="text-center">{{ evaluationOption.is_correct ? $t('globalTrans.yes') : $t('globalTrans.no') }}</td>
                      <td>{{ evaluationOption.option_en }}</td>
                      <td>{{ evaluationOption.option_bn }}</td>
                      <td><a v-if="evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a></td>
                      </tr>
                  </table>
                  </div>
              </b-col>
              </b-row>
            </b-overlay>
          </template>
        </card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
    components: {
      ListReportHead
    },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre Exam' : 'প্রাক পরীক্ষা', value: 1 },
        { text: this.currentLocale === 'en' ? 'Post Exam' : 'পরবর্তী পরীক্ষা', value: 2 }
      ]
    },
    examTypeName: function () {
      return this.examTypeList.find(item => item.value === this.formData.exam_type).text
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'Written' : 'লিখিত', value: 2 }
      ]
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getQuestionType (questionType) {
      return this.questionTypeList.find(item => item.value === questionType).text
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.evaluation') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
