<template>
    <b-container fluid>
         <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.evaluation')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="exam_date"
                >
                  <template v-slot:label>
                    {{ $t('elearning_tim.exam_date') }}
                  </template>
                  <b-form-input
                      class="fromDate"
                      id="exam_date"
                      v-model="search.exam_date"
                      :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('elearning_config.organization')"
                    label-for="organization"
                >
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="orgList"
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="training_title_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.training_title')}}
                  </template>
                  <v-select name="training_title_id"
                    v-model="search.training_title_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="trainingTitleList"
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="course_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_tim.course_name')}}
                  </template>
                  <v-select name="course_id"
                    v-model="search.course_id"
                    label="text"
                    :reduce="item => item.value"
                    :options="courseList"
                    :placeholder="$t('globalTrans.select')"
                  />
                </b-form-group>
              </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
          </template>
        </card>
        <b-row>
            <b-col md="12">
                 <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.evaluation')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" >
                          <router-link :to="{ path: '/training-e-learning-service/tim/evaluation-form'}" size="sm">
                            <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                          </router-link>
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(exam_date)="data">
                                        <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-4 @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </a>
                                        <a href="javascript:" class="btn_table_action table_action_edit"> <router-link :to="{ path: '/training-e-learning-service/tim/evaluation-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill m-0"></i> </router-link></a>
                                          <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.evaluation') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationList, evaluationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
          exam_date: '',
          org_id: 0,
          training_title_id: 0,
          course_id: 0
        },
        courseList: []
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainingTitleList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_tim.exam_date'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_tim.course_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'exam_date' },
          { key: 'org_bn' },
          { key: 'training_title_bn' },
          { key: 'course_name_bn' },
          { key: 'status' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'exam_date' },
          { key: 'org' },
          { key: 'training_title' },
          { key: 'course_name' },
          { key: 'status' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      'search.training_title_id': function (newValue) {
        this.courseList = this.getCourseList(newValue)
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
      })
      this.loadData()
    },
    mounted () {
      flatpickr('.fromDate', {})
    },
    methods: {
      getCourseList (trainingTitleId) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (trainingTitleId) {
          return courseList.filter(item => item.training_title_id === trainingTitleId)
        }
        return courseList
      },
      async searchData () {
        this.loadData()
      },
      remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, evaluationToggleStatus, item, 'training_e_learning')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, evaluationList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            customItem.org = orgObj.text_en
            customItem.org_bn = orgObj.text_bn
          } else {
            customItem.org = ''
            customItem.org_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            customItem.office_type = OfficeTypeListObj.text_en
            customItem.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            customItem.office_type = ''
            customItem.office_type_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            customItem.office = officeObj.text_en
            customItem.office_bn = officeObj.text_bn
          } else {
            customItem.office = ''
            customItem.office_bn = ''
          }
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            customItem.fiscal_year = fiscalYearObj.text_en
            customItem.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            customItem.fiscal_year = ''
            customItem.fiscal_year_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }
          const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          if (typeof courseObj !== 'undefined') {
            customItem.course_name = courseObj.text_en
            customItem.course_name_bn = courseObj.text_bn
          } else {
            customItem.course_name = ''
            customItem.course_name_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
