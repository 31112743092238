import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
              body: [
                [
                  { text: vm.$t('elearning_tim.exam_type'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.examTypeName, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_tim.exam_date'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$options.filters.dateFormat(data.exam_date), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.course'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.course_name_bn : data.course_name, style: 'td', alignment: 'left' },
                  { },
                  { },
                  { }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        data.evaluation_questions.filter((evaluationQuestion, questionIndex) => {
          pdfContent.push(
              {
                table: {
                  style: 'tableData',
                  headerRows: 0,
                  widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
                  body: [
                    [
                      { text: vm.$t('elearning_tim.question') + ' ' + vm.$n(questionIndex + 1), style: 'headerPort1', alignment: 'left', decoration: 'underline', colSpan: 6 },
                      { },
                      { },
                      { },
                      { },
                      { }
                    ],
                    [
                      { text: vm.$t('elearning_tim.question_en'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: evaluationQuestion.question, style: 'td', alignment: 'left' },
                      { text: vm.$t('elearning_tim.question_bn'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: evaluationQuestion.question_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                      { text: vm.$t('elearning_tim.description_en'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: evaluationQuestion.description, style: 'td', alignment: 'left' },
                      { text: vm.$t('elearning_tim.description_bn'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: evaluationQuestion.description_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                      { text: vm.$t('elearning_tim.question_type'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: vm.getQuestionType(evaluationQuestion.question_type), style: 'td', alignment: 'left' },
                      { text: vm.$t('elearning_tim.marks'), style: 'th', alignment: 'right' },
                      { text: ':', style: 'td', alignment: 'center' },
                      { text: evaluationQuestion.marks, style: 'td', alignment: 'left' }
                    ]
                  ]
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0
                  },
                  vLineWidth: function (i, node) {
                    return 0
                  }
                }
              }
          )
          // option
          if (evaluationQuestion.evaluation_options.length) {
            let allRows = []
            allRows = [
              [
                { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
                { text: vm.$t('elearning_tim.is_correct'), style: 'th', alignment: 'center' },
                { text: vm.$t('elearning_tim.option_en'), style: 'th', alignment: 'center' },
                { text: vm.$t('elearning_tim.option_bn'), style: 'th', alignment: 'center' }
              ]
            ]
            // table body
            evaluationQuestion.evaluation_options.filter((evaluationOption, optionIndex) => {
              const rowItem = [
                { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
                { text: evaluationOption.is_correct ? vm.$t('globalTrans.yes') : vm.$t('globalTrans.no'), style: 'td', alignment: 'center' },
                { text: evaluationOption.option_en, style: 'td', alignment: 'center' },
                { text: evaluationOption.option_bn, style: 'td', alignment: 'center' }
              ]
              allRows.push(rowItem)
            })
            pdfContent.push({ text: '', style: 'tableMargin' })
            pdfContent.push({
              table: {
                headerRows: 1,
                widths: '*',
                body: allRows
              }
            })
          }
        })

        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            bold: true,
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
              margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            bold: true,
            fontSize: (i18n === 'bn') ? 13 : 12,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          tableMargin: {
            margin: [0, 15, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
